import React from "react";
import "../Styles/Heros.css";
import { useTranslation } from "react-i18next";

export default function Heros() {
  const { t } = useTranslation();
  return (
    <div className="heros-section">
      <div className="heros-content">
        <h3 className="heros-text">mygaminggalaxy.com</h3>
        <h1 className="heros-title">
          {t("heros.title")}
        </h1>
        <p className="heros-description">
        {t("heros.description")}
        </p>
        <button
  className="heros-button"
  onClick={() => window.scrollBy({ top: 700, behavior: "smooth" })}
>
{t("games.play-button")}
</button>

      </div>
    </div>
  );
}
